import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { pageData } from '../actions/actions';
import loadable from '@loadable/component';
import axios from "axios";
import {Helmet} from "react-helmet";
// import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import Breadcrumbs from '../components/Breadcrumbs';
// import FaqMenu from '../components/FaqMenu';
// import FreeBanner from '../components/FreeBanner';
// import { hexToRGB } from '../lib/utils';
// import phone from '../assets/4.png';
// import phone2 from '../assets/2.png';
// import form from '../assets/5.png';
// import TrustBox from '../components/TrustBox';
import ContactCTA from '../components/ContactCTA';
import GuidesFilter from './GuidesFilter';

const PageLoader = loadable(() => import("../components/PageLoader"));
function Faqs(props) {
  const [pageId, setPageId] = useState('');
  const [guideData, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  let allPageData = useSelector((state) => state.pageData);
  // let footerData = useSelector((state) =>state.footerData);  
  // const pageExtras = useSelector((state) =>state).setTrustpilot;
  let seoGuidePages = useSelector((state) => state.seoGuidePages).guidePages.edges;
  // console.log(pageExtras);
  const data = props;
  let pageType = data.match.params.type;
  if (!pageType) {
    pageType = props.type;
  }
  // console.log(pageType);
  const loadPage = async () => {
    let api = process.env.REACT_APP_API_URL;
    await axios.all([
      axios.get(api + 'wp-json/wp/v2/pages?_embed&slug=' + pageType + '-guide'),
    ])
      .then(axios.spread((result) => {
        // let getData = data;
        // data = result.data['0'];
        // console.log(result);
        allPageData[pageType+'-guides'] = result.data['0'];
        // console.log(allPageData);
        dispatch(pageData(allPageData));
        setPageId(pageType+'-guides');
        setIsLoaded(true);
        setData(result.data['0']);
      }))
      .catch(error => {
        setPageId(pageType+'-guides');
        setIsLoaded(false);
        // setData(error);
        setError(error);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (allPageData[pageType+'-guides']) {
      setData(allPageData[pageType+'-guides']);
      setPageId(pageType+'-guides');
      setIsLoaded(true);
    } else {
      // console.log('loading '+pageType);
      loadPage();
    }
    const interval = setTimeout(() => {
      loadPage();
    }, 5000);
    return () => {
      clearTimeout(interval);
    };
  }, [pageType]);
  let faqsData = useSelector((state) => state.faqsData);
  let servicesName = useSelector((state) => state.servicesName);
  // console.log(guideData?.acf?.free_banner?.value);
  // let bg = '';
  // if (faqsData[pageType][0].acf?.faq_background) {
  //   bg = faqsData[pageType][0].acf?.faq_background.url;
  // }
  let faqs = faqsData[pageType];
  let service_type = pageType;
  let themeColor = '';
  if (servicesName[service_type]) {
    // themeColor = hexToRGB(servicesName[service_type].services.themeColor, servicesName[service_type].services.themeOpacity);
    themeColor = servicesName[service_type].services.themeColor;
  }
  if (error) {
    return (<div>Error: {error.message}</div>);
  } else if (!isLoaded) {
    return (
      <PageLoader />
    );
  } else if (isLoaded && guideData) {
    return (
      <div className="main-content guide-page-container" style={{ "--theme-color": themeColor }}>
        {/* {console.log(guideData?.acf?.meta_title)} */}
        {guideData?.acf && 
        // Object.keys(guideData).length === 0 &&
          <Helmet>
            <meta charSet="utf-8" />
            {(guideData.acf.meta_title)?<title>{guideData.acf.meta_title}</title>:<title>MariannaFS | Fee Free Mortgage Broker | Excellent 4.9/5 Rating</title>}
            {/* <link rel="canonical" href={`${window.location.href}`} /> */}
            <link rel="canonical" href={`https://mariannafs.co.uk${window.location.pathname}`} />
            {(guideData.acf.meta_description)?<meta name="description" content={guideData.acf.meta_description} />:<meta name="description" content={`${guideData.title.rendered} We offer free mortgage advise for your mortgages. With excellent customer rating  4.9/5 get in touch to see how can we help.`} />}
            {(guideData.acf.meta_keywords)?<meta name="keywords" content={guideData.acf.meta_keywords} />:<meta name="keywords" content={``} />}
          </Helmet>
        }
        {props.breadcrumbs && 
          <Breadcrumbs pageId={pageType} title={faqs[0].post_title} pageUrl='/services' active="guides" />
        }
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 px-0">
              <h1 className="py-5 mb-0 text-center dark-text guide-main-header">{faqs[0].post_title} Guides</h1>
            </div>
            <div className="col-md-12">
              <>
                {(guideData?.acf?.content_section_1) && false &&
                  <div className='guides-content-section container max-900 py-5'>
                    <div className="guides-content-container" dangerouslySetInnerHTML={{ __html: guideData.acf.content_section_1 }}></div>
                  </div>
                }
                {(guideData?.acf?.content_section_2) && false &&
                  <div className='guides-content-section container max-900 pb-5'>
                    <h2 className="py-5 mb-0 text-center font-underline">Frequently Asked Questions</h2>
                    <div className="guides-content-container" dangerouslySetInnerHTML={{ __html: guideData.acf.content_section_2 }}></div>
                  </div>
                }
                {
                  seoGuidePages &&
                  <div className='max-900 container pb-4'>
                    {
                      false && 
                      <h4 className="text-center font-medium font-underline">More Guides</h4>
                    }
                    <GuidesFilter moreLink={true} filter={pageType} />
                  </div>
                }
              </>
            </div>
          </div>          
        </div>
        <ContactCTA />
        {(guideData?.acf?.footer_disclaimer) &&
          <div className="container">
            <div className="row">
              <div className="col-md-12 my-5">
                <div className="container" dangerouslySetInnerHTML={{ __html: guideData.acf.footer_disclaimer }}></div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }else return <></>;
}
export default Faqs;