import React, { useState, useEffect } from 'react';
import { AddComma } from './index';

function ContractorCalculator(props){
  const [dayRate, setDayRate] = useState(5000); 
  const [result, setResult] = useState(0); 
  function handleChange(e){
    let value;
    if((e.target.value)){
      value = e.target.value;
    }else{
      value = 0;
    }
    if(value<10000000000){
      if(e.target.name==="dayRate"){
        setDayRate(parseFloat(value));
      }
    }
  }
  function calculate(){
    let temp;
    let i3 = dayRate ? dayRate : 0; 
    temp = (((parseInt(i3))*5*48*5).toFixed(2));
    setResult(temp);
  }
  useEffect(() => {    
    calculate();
    return () => {      
    }
  }, [dayRate]);
  return (
    <div className="calculator-wrapper my-31">
      <div className="range-container">
        <h4 className="text-center">Contractor Calculator</h4>
        <div className="text-center d-none">Our mortgage calculator is a great first step for anyone who’s thinking of moving home. But Many lenders have different requirements and therefore no calculator will ever cater for all such variations. Each lenders criteria n is different regarding income, property valuation and your own personal situation. Remember the actual loan amount will always be subject to a full assessment and underwriting. <br/>This calculator is design to give you a rough Idea. If you need something more accurate, please contact us so that a qualified mortgage advisor and can assess and advise.</div>
        <div className="option-label d-flex">
          Day Rate:
          <div className="range-value-container ml-2">
            £ <span className="range-value">{AddComma(dayRate)}</span>
          </div>
        </div>
        <div className="mb-2">
          <input className="range-slider" type="text" min="50000" max="1000000" name="dayRate"
            value={dayRate} onChange={handleChange} />
        </div>
        <div>
          <div>Result : £{AddComma(result)}</div>
        </div>
      </div>
    </div>
  );
}
export default ContractorCalculator;