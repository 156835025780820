import React, { useState, useEffect } from 'react';
import axios from "axios";
import XMLParser from 'react-xml-parser';
import { parse } from 'fast-xml-parser';
import { ImSpinner3 } from 'react-icons/im';
import {Table} from 'react-bootstrap';

function UpdateLenderLogos(props) {
    const [activeRates, setActiveRates] = useState([]);
    const [Logos, setLogos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingLenders, setLoadingLenders] = useState(true);
    const [loadingLogos, setLoadingLogos] = useState(true);
    const [error, setError] = useState('');
    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        source.cancel();
        // console.log(data)
        let api = `https://mariannafs.co.uk/promotion/wp-json/get_lenders/v1/get`;
        let api2 = `https://mariannafs.co.uk/promotion/wp-json/get_logos/v1/get`;
        const loadData = async () => {
            try {
                setLoading(true);
                await axios.all([
                    axios.post(api)
                ]).then(axios.spread((rates) => {
                    // console.log(rates.data);
                    setLoading(false);
                    let obj = parse(rates.data);
                    if(obj['s:Envelope']['s:Body']['GetLenderListResponse']){
                        let responseData = obj['s:Envelope']['s:Body']['GetLenderListResponse']['Lenders']['a:MBPublicSchemeLenders'];
                        // console.log(responseData);
                        // console.log('lenders downloaded');
                        setLoadingLenders(false);
                        // responseData.sort(function (a, b) {
                        //     return a['a:InitialRate']>(b['a:InitialRate']);
                        // });
                        let arrayID = Object.keys(responseData).map((item, i)=>{
                            return(
                                responseData[item]['a:ID']
                            )
                        });
                        // console.log(arrayID);
                        const data = {};
                        data['id'] = arrayID;
                        axios.all([
                            axios.post(api2, null, {params: data})
                        ]).then(axios.spread((lenderLogo) => {
                            let objNew = parse(lenderLogo.data)['s:Envelope']['s:Body']['GetLenderLogosResponse']['Logos']['a:MBPublicImageFile'];
                            // console.log(objNew);
                            setLoadingLogos(false);
                            // console.log('logos downloaded');
                            const fullLogos = responseData.map(t1 => ({...t1, ...objNew.find(t2 => t2['a:LenderID'] === t1['a:ID'])}));
                            setLogos([...fullLogos]);
                            // var i,j, temporary, chunk = 50;
                            // for (i = 0,j = fullLogos.length; i < j; i += chunk) {
                            //     temporary = fullLogos.slice(i, i + chunk);
                            //     // do whatever
                            //     let dataLogos = {};
                            //     // dataLogos['logos'] = JSON.stringify(temporary);
                            //     dataLogos['logos'] = (temporary);
                            //     console.log(dataLogos);
                            //     let formData = new FormData();
                            //     formData.append('file',dataLogos);
                            //     const config = {headers: {'content-type': 'multipart/form-data'}};
                            //     axios.all([
                            //         axios.post(api3, config, {params: dataLogos})
                            //     ]).then(axios.spread((dataSaved) => {
                            //         let dataResponse = dataSaved.data;
                            //         console.log(dataSaved);                                
                            //     })).then(() => {
                            //         // console.log(data);
                            //     });
                            // }
                            // const formData = new FormData();
                            // formData.append('file',dataLogos);
                            // const config = {
                            //     headers: {
                            //         'content-type': 'multipart/form-data'
                            //     }
                            // }
                            // axios.post(api3, formData,config);
                        })).then(() => {
                            // console.log(data);
                        });
                        setError('');
                    }else{
                        console.log(obj['s:Envelope']['s:Body']['GetLenderListResponse']['GetLenderListResult']['a:ErrorCode']);
                        // setError(obj['s:Envelope']['s:Body']['GetLenderListResponse']['GetLenderListResult']['a:ErrorCode']);
                        setActiveRates([]);
                    }
                    // console.log(data);
                    // let jsonDataFromXml = new XMLParser().parseFromString(rates.data);
                    // console.log(jsonDataFromXml);
                })).then(() => {
                    // console.log(data);
                });
            } catch (error) {
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log("cancelled");
                    // setError(error);
                } else {
                    throw error;
                }
            }
        };
        loadData();
        getLenderList();
        return () => {
            source.cancel();
        };
    }, []);
    const getLenderList = async () => {
        let api3 = `https://mariannafs.co.uk/promotion/wp-json/get_saved_lenders/v1/get`;
        try {
            await axios.all([
                axios.post(api3)
            ]).then(axios.spread((lenders) => {
                console.log(lenders);
                // let obj = parse(rates.data);
                // if(obj['s:Envelope']['s:Body']['GetSchemesExResponse']){
                //     let responseData = obj['s:Envelope']['s:Body']['GetSchemesExResponse']['objSchemes']['a:MBPublicSchemeSummaryEX'];
                //     console.log(responseData);
                //     responseData.sort(function (a, b) {
                //         return a['a:InitialRate']>(b['a:InitialRate']);
                //     });
                //     setError('');
                //     setActiveRates([...responseData]);
                // }else{
                //     console.log(obj['s:Envelope']['s:Body']['s:Fault']['faultstring']);
                //     setError(obj['s:Envelope']['s:Body']['s:Fault']['faultstring']);
                //     setActiveRates([]);
                // }
                // console.log(data);
                // let jsonDataFromXml = new XMLParser().parseFromString(rates.data);
                // console.log(jsonDataFromXml);
            })).then(() => {
                // console.log(data);
            });
        } catch (error) {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log("cancelled");
                // setError(error);
            } else {
                throw error;
            }
        }
    };
    // console.log(props.showRates);
    return (
        <div className={`rates-api-container ${(loading)?'loading':''}`}>
            <ImSpinner3 className="spinner" color="#fff" size={20} />
            <div className="container overflow-visible">
                <div className="">
                    <div className="col-md-12">
                        Fetching lenders {(loadingLenders)?'fetching':'done'}
                    </div>
                    <div className="col-md-12">
                        Fetching logos {(loadingLogos)?'fetching':'done'}
                    </div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                            <th>LenderID</th>
                            <th>Lender Name</th>
                            <th>Lender Image</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            Logos.map((item,i)=>{
                                return(
                                    <tr key={i}>
                                        <td>
                                            {item['a:LenderID']}
                                        </td>
                                        <td>
                                            {item['a:Name']}
                                        </td>
                                        <td>
                                            <img src={`data:image/png;base64,${item['a:Data']}`} alt={item['a:Name']} title={item['a:Name']} style={{maxWidth : '100px'}} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
}
export default UpdateLenderLogos;
