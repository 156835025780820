import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import {Accordion, Card, useAccordionToggle} from 'react-bootstrap';
import Breadcrumbs from '../components/Breadcrumbs';
// import FaqMenu from '../components/FaqMenu';
import RatesApi from '../components/RatesApi';
import DealsMenu from '../components/DealsMenu';
// import {hexToRGB} from '../lib/utils';

function SingleDeal(props) { 
  const [rateShow, setRateShow] = useState([{"value": "first-time-buyer", "label": "First Time Buyer Rate"}]);
  let faqsData = useSelector((state) =>state.faqsData);
  let servicesName = useSelector((state) => state.servicesName);
  useEffect(() => {
    window.scrollTo(0, 0);
    let temp = rateShow;
    temp[0].value = props.match.params.type;
    if(temp[0].value === "undefined"){
      temp[0].value = "first-time-buyer";
    }else{
      temp[0].label = servicesName[props.match.params.type]?.title;
    }
    setRateShow([...temp]);
  }, []);
  const data = props;
  let pageType = data.match.params.type;
  if(!pageType){
    pageType = props.type;
  }
  let themeColor = '';
  if(servicesName[pageType]){
    // themeColor = hexToRGB(servicesName[pageType].services.themeColor, servicesName[pageType].services.themeOpacity);
    themeColor = servicesName[pageType].services.themeColor;
  }
  // console.log(pageType);
  let faqs = faqsData[pageType];
  return (
    <div className="main-content deals-page-container" style={{"--theme-color": themeColor}}>
      {props.breadcrumbs && faqs &&
        <Breadcrumbs pageId={pageType} title={faqs[0].post_title} pageUrl='/services' active="deals" />
      }
      <div className="container-fluid">
        <div className="container py-5 mt-2">
          <div className="page-header">
            {
              faqs &&
              <h2 className="pb-3 mb-0 text-center dark-text">{faqs[0].post_title} Deals</h2>
            }
            <RatesApi showRates={rateShow} />
          </div>          
          <DealsMenu currentFaq={pageType} />
        </div>
      </div>
    </div>
  );
}
export default SingleDeal;
