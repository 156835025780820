import { useEffect, useRef, useState } from 'react';
import GuideCTA from '../GuideCTA';
import LenderContent from './LenderContent';

function StickySideSection({ stickyContent, lendersData, lendersID }) {
    const stickyElementRef = useRef(null);
    const blogContentRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    /*useEffect(() => {
        const stickyElement = stickyElementRef.current;
        const blogContent = blogContentRef.current;
        function onScroll() {
            if (!stickyElement || !blogContent) return;
            const rect = blogContent.getBoundingClientRect();
            const blogContentTopVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
            const blogContentBottomVisible = rect.bottom >= 0 && rect.bottom <= window.innerHeight;
            console.log("top=>",rect.top, "bottom=>",rect.bottom, "top=>",window.innerHeight)
            if (blogContentTopVisible || blogContentBottomVisible) {
                stickyElement.style.position = 'sticky';
            } else {
                stickyElement.style.position = 'relative';
            }
        }
        window.addEventListener('scroll', onScroll);
        onScroll(); // Initial check in case the element is already in the viewport
        return () => window.removeEventListener('scroll', onScroll); // Cleanup event listener on unmount
    }, []);*/
    useEffect(() => {
        const stickyElement = stickyElementRef.current;
        const blogContent = blogContentRef.current;
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            });
        }, observerOptions);
        if (blogContent) {
            observer.observe(blogContent);
        }
        return () => {
            if (blogContent) {
                observer.unobserve(blogContent);
            }
        };
    }, []);
    // console.log(lendersData)
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                    <div className={`${isSticky ? 'sticky-guide-top' : ''}`} ref={stickyElementRef}>
                        {
                            stickyContent ?
                            <div dangerouslySetInnerHTML={{ __html: stickyContent }}></div> :
                            <DummyStickyHTML />
                        }
                    </div>
                </div>
                <div className="col-md-8">
                    <div ref={blogContentRef}>
                        <LenderContent lendersData={lendersData} lendersID={lendersID} />
                        {
                            false && lendersData && lendersData.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <div dangerouslySetInnerHTML={{ __html: item?.content }}></div>
                                        {
                                            item.cta_text &&
                                            <GuideCTA cta_text={item.cta_text} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StickySideSection;
function DummyStickyHTML() {
    return (
        <table
            style={{
                textAlign: "center",
                fontFamily: "TrustpilotDefaultFont,Arial,sans-serif",
                color: "#002f6a",
                maxWidth: 500,
                margin: "auto"
            }}
        >
            <tbody>
                <tr>
                    <td style={{ fontSize: 16, fontWeight: 600 }}>Free Mortgage Broker</td>
                </tr>
                <tr>
                    <td style={{ padding: "20px 0" }}>
                        <img
                            width={200}
                            src="https://cdn.trustpilot.net/consumersite-businessunitimages/5fbd58f6ab2867000112d0fc/profile-description/mfs.png"
                            alt=""
                        />
                    </td>
                </tr>
                <tr>
                    <td style={{ fontWeight: 600 }}>TRUSTED MORTGAGE BROKER</td>
                </tr>
                {/* <tr>
                    <td>We are fee free</td>
                </tr>
                <tr>
                    <td>Swift &amp; exclusive deals</td>
                </tr>
                <tr>
                    <td>We do all the hard work</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{ fontWeight: 500 }}>
                        MariannaFS is one of the best rated Free Online Mortgage Broker. We
                        cover all of UK offering fee free online mortgage advice. Get in touch
                        to speak to our advisor
                    </td>
                </tr> */}
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>
                        <table style={{ textAlign: "center", margin: "auto", width: "80%" }}>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "bottom", padding: "0 5px" }}>
                                        <a
                                            style={{
                                                display: "block",
                                                padding: 5,
                                                fontWeight: 600,
                                                color: "#fff",
                                                backgroundColor: "#F2161B",
                                                textDecoration: "none"
                                            }}
                                            href="https://mariannafs.co.uk/remortgaging"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            <img
                                                width={50}
                                                src="https://cdn.trustpilot.net/consumersite-businessunitimages/5fbd58f6ab2867000112d0fc/profile-description/website.png"
                                                alt=""
                                            />
                                            <br />
                                            Free Remortgage
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom", padding: "0 5px" }}>
                                        <a
                                            style={{
                                                display: "block",
                                                padding: 5,
                                                fontWeight: 600,
                                                color: "#fff",
                                                backgroundColor: "#63C73A",
                                                textDecoration: "none"
                                            }}
                                            href="https://api.whatsapp.com/send?phone=4402080902043"
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            <img
                                                width={50}
                                                src="https://cdn.trustpilot.net/consumersite-businessunitimages/5fbd58f6ab2867000112d0fc/profile-description/whatsapp.png"
                                                alt=""
                                            />
                                            <br />
                                            Chat on Whatsapp
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom", padding: "0 5px" }}>
                                        <a
                                            style={{
                                                display: "block",
                                                padding: 5,
                                                fontWeight: 600,
                                                color: "#fff",
                                                backgroundColor: "#1FB3E9",
                                                textDecoration: "none"
                                            }}
                                            href="tel:4402080902043"
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            <img
                                                width={50}
                                                src="https://cdn.trustpilot.net/consumersite-businessunitimages/5fbd58f6ab2867000112d0fc/profile-description/call.png"
                                                alt=""
                                            />
                                            <br />
                                            Connect on call
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>

    )
}
