import React, { useState, useEffect } from 'react';
import { AddComma } from './index';

function BuyToLetBorrow(props){
  const [monthlyRent, setMonthlyRent] = useState(300); 
  const [propertyValue, setPropertyValue] = useState(70000); 
  const [knowValue, setKnowValue] = useState(false); 
  const [result, setResult] = useState(0); 
  function handleChange(e){
    let value;
    if((e.target.value)){
      value = e.target.value;
    }else{
      value = 0;
    }
    if(value<10000000000){
      if(e.target.name==="propertyValue"){
        setPropertyValue(parseFloat(value));
      }
      if(e.target.name==="monthlyRent"){
        setMonthlyRent(parseFloat(value));
      }
    }
  }
  function invertState(){
    setKnowValue(!knowValue);
  }
  function calculate(){
    if(knowValue){
      setResult((((monthlyRent*12)/1.2)/5)*100);
    }else{
      setResult((((monthlyRent*12)/1.2)/5)*100);
      if((((monthlyRent*12)/1.2)/5)*100>=propertyValue*0.75){
        setResult(propertyValue*0.75);
      }
    }
  }
  useEffect(() => {    
    calculate();
    return () => {      
    }
  }, [propertyValue, monthlyRent, knowValue]);
  return (
    <div className="calculator-wrapper my-31">
      <div className="range-container">
        <h4 className="text-center">Buy-to-let how much can I borrow calculator</h4>
        <div className="text-center"></div>
        <div className="new-label-input"> 
          <div className="option-label d-flex">
            <div className="option-label-text ml-2 px-1">Monthly Rental:</div>
            <div className="range-value-container px-1">
              £ <span className="range-value">{AddComma(monthlyRent)}</span>
            </div>
          </div>
          <div className="mb-2">
            <input className="range-slider" type="text" name="monthlyRent"
              value={monthlyRent} onChange={handleChange} />
          </div>
          <div className={`option-label d-flex`}>
            <div className="option-label-text ml-2 px-1">Property Value:</div>
            <div className="range-value-container px-1">
              £ <span className="range-value">{AddComma(propertyValue)}</span>
            </div>
          </div>
          <div className={`mb-2${knowValue? " disabled-data":""}`}>
            <input className="range-slider" type="text" name="propertyValue"
              value={propertyValue} onChange={handleChange} />
          </div>
          <div className="d-flex justify-content-between">
            <label className='custom-control custom-checkbox custom-radio d-block text-left'>
              <input className='custom-control-input1' type='checkbox' name="knowValue" checked={knowValue? "checked":""} onChange={invertState} />
              <span className='custom-control-label custom-label'>Value unknown</span>
            </label>
            <div>Result : £{AddComma(result)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BuyToLetBorrow;