const initial = {};
const apiRates = (state = initial, action)=>{
    switch(action.type){
        case 'API_RATES':
            return state = action.data;
        default: 
            return state;
    }
}

export default apiRates;