import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaHeadphonesAlt, FaRegEdit } from 'react-icons/fa';

function GuideCTA(props) {
  return (
    <div className='guides-content-section new-banner-container mx-auto max-700 py-4'>
      <div className={`container1 banner-cta mt-0 ${props.containerClasses ? props.containerClasses : ''}`}>
        {/* <h1 className="text-center font-bold pb-3 m-0">Get in touch</h1> */}                  
        <h3 className="section-header text-center py-3 m-0 text-transform-none">{props.cta_text}</h3>
        <div className={`${!props.align ? 'row' : ''} justify-content-center mt-2`}>
          <div className={`${!props.align ? 'col-4' : ''} text-center pb-4`}>
            <FaPhoneAlt size={70} className='d-block mx-auto mb-2 cta-icon' color='#FF0408' />
            <a href="tel:02080902043" className="mfs-link">Call us on 02080902043</a>
          </div>
          <div className={`${!props.align ? 'col-4' : ''} text-center pb-4`}>
            <FaHeadphonesAlt size={70} className='d-block mx-auto mb-2 cta-icon' color='#43DB03' />
            <Link to="/contact" className="mfs-link">Request a callback</Link>
          </div>
          <div className={`${!props.align ? 'col-4' : ''} text-center pb-4`}>
            <FaRegEdit size={70} className='d-block mx-auto mb-2 cta-icon' color='#0091C5' />
            <Link to="/" className="mfs-link">Submit the form</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GuideCTA;
