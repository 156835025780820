import React, { useState, useEffect } from 'react';
import { AddComma } from './index';

function MonthlyRepayments(props) {
  const [loan, setloan] = useState(50000);
  const [interest, setInterest] = useState(5.99);
  const [term, setTerm] = useState(25);
  const [monthlyPay, setMonthlyPay] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  function handleChange(e) {
    let value;
    if ((e.target.value)) {
      value = e.target.value;
    } else {
      value = 0;
    }
    if (value < 10000000000) {
      if (e.target.name === "loan") {
        setloan(parseFloat(value));
      }
      if (e.target.name === "interest") {
        setInterest(parseFloat(value));
      }
      if (e.target.name === "term") {
        setTerm(parseFloat(value));
      }
    }
  }
  function calculate() {
    let principal = parseFloat(loan);
    let interestRate = parseFloat(interest) / 100 / 12;
    let payments = parseFloat(term) * 12;
    let x = Math.pow(1 + interestRate, payments);
    let monthly = (principal * x * interestRate) / (x - 1);
    setMonthlyPay(AddComma(monthly.toFixed(2)));
    setTotalPay(AddComma((monthly * payments).toFixed(2)));
    setTotalInterest(AddComma(((monthly * payments) - principal).toFixed(2)));
  }
  useEffect(() => {
    calculate();
    return () => {
    }
  }, [loan, interest, term]);
  return (
    <div className="calculator-wrapper my-31">
      <div className="range-container">
        <h4 className="text-center">Calculate monthly payments</h4>
        <div className="text-center d-none">Calculate your monthly mortgage payments for both repayment and interest-only mortgages.</div>
        <div className="new-label-input">
          <div className="option-label d-flex">
            <div className="option-label-text ml-2 px-1">Amount of the loan (£):</div>
            <div className="range-value-container px-1">
              <span className="range-value">{AddComma(loan)}</span>
            </div>
          </div>
          <div className="mb-2">
            <input className="range-slider" type="text" name="loan"
              value={loan} onChange={handleChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 new-label-input">
            <div className="option-label d-flex">
              <div className="option-label-text ml-2 px-1">Annual interest (%):</div>
              <div className="range-value-container px-1">
                <span className="range-value">{AddComma(interest)}</span>
              </div>
            </div>
            <div className="mb-2">
              <input className="range-slider" type="number" name="interest" min="1" max="8" step="0.01" value={interest} onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-6 new-label-input">
            <div className="option-label d-flex">
              <div className="option-label-text ml-2 px-1">Term (Years):</div>
              <div className="range-value-container px-1">
                <span className="range-value">{AddComma(term)}</span>
              </div>
            </div>
            <div className="mb-2">
              <input className="range-slider" type="number" name="term" min="5" max="40" step="1" value={term} onChange={handleChange} />
            </div>
          </div>
        </div>
        {/* <div className="option-label d-flex">
          Annual interest (%):
          <div className="range-value-container ml-2">
            <span className="range-value">{AddComma(interest)}</span>
          </div>
        </div> */}
        {/* <div className="mb-2">
          <input className="range-slider" type="range" min="1" max="8" step="0.01" name="interest" value={interest} onChange={handleChange} />
        </div>
        <div className="option-label d-flex">
          Term (Years):
          <div className="range-value-container ml-2">
            <span className="range-value">{AddComma(term)}</span>
          </div>
        </div>
        <div className="mb-2">
          <input className="range-slider" type="range" min="5" max="40" name="term"
            value={term} onChange={handleChange} />
        </div> */}
        <div>
          <div>Monthly payment : £{monthlyPay}</div>
          <div className='d-none'>Total payment : £{totalPay}</div>
          <div className='d-none'>Total interest : £{totalInterest}</div>
        </div>
      </div>
    </div>
  );
}
export default MonthlyRepayments;