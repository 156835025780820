// import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { RiFileEditLine } from 'react-icons/ri';
// import { TfiHeadphoneAlt } from 'react-icons/tfi';
// import { BsTelephone } from 'react-icons/bs';
import { FaPhoneAlt, FaHeadphonesAlt, FaRegEdit } from 'react-icons/fa';
import ReactStars from "react-rating-stars-component";

function ContactCTA(props) {
  return (
    <div className={`container ${props.containerClasses?props.containerClasses:''}`}>
      {/* <h1 className="text-center font-bold pb-3 m-0">Get in touch</h1> */}
      {!props.hideHeader &&
        <h3 className="section-header text-center pb-5 pt-md-5 m-0 text-transform-none">We will find best mortgage deal suitable to you</h3>
      }
      {props.ratingHeader &&
      <div className="ratings-overview">
        <div className='d-flex justify-content-center review-section'>
          <div className="rating-heading pr-2" style={{'minWidth':'0'}}>Our Ratings</div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="rating-number pr-3">5.0</div>
            <ReactStars
              count={5} value={5} isHalf={true} edit={false}
              size={35}
              activeColor="#fe730a" color="transparent"
            />
          </div>
        </div>
      </div>
      }
      <div className={`${!props.align?'row':''} justify-content-center mt-2`}>
        <div className={`${!props.align?'col-4':''} text-center pb-4`}>
          <FaPhoneAlt size={70} className='d-block mx-auto mb-2 cta-icon' color='#FF0408' />
          <a href="tel:02080902043" className="mfs-link">Call us on 02080902043</a>
        </div>
        <div className={`${!props.align?'col-4':''} text-center pb-4`}>
          <FaHeadphonesAlt size={70} className='d-block mx-auto mb-2 cta-icon' color='#43DB03' />
          <Link to="/contact" className="mfs-link">Request a callback</Link>
        </div>
        <div className={`${!props.align?'col-4':''} text-center pb-4`}>
          <FaRegEdit size={70} className='d-block mx-auto mb-2 cta-icon' color='#0091C5' />
          <Link to="/" className="mfs-link">Submit the form</Link>
        </div>
      </div>
    </div>
  );
}
export default ContactCTA;
