import React, { useState, useEffect } from 'react';
// import loadable from '@loadable/component';
// import { Link } from 'react-router-dom';
import SelfEmployed from './Calculators/SelfEmployed';
import Borrow from './Calculators/Borrow';
import BuyToLet from './Calculators/BuyToLet';
import MonthlyRepayments from './Calculators/MonthlyRepayments';
import BuyToLetBorrow from './Calculators/BuyToLetBorrow';
import ContractorCalculator from './Calculators/ContractorCalculator';
import RemortgageCalculator from './Calculators/RemortgageCalculator';

// const SelfEmployed = loadable(() => import('./Calculators/SelfEmployed'));
// const Borrow = loadable(() => import('./Calculators/Borrow'));
// const BuyToLet = loadable(() => import('./Calculators/BuyToLet'));
// const MonthlyRepayments = loadable(() => import('./Calculators/MonthlyRepayments'));
// const BuyToLetBorrow = loadable(() => import('./Calculators/BuyToLetBorrow'));
// const ContractorCalculator = loadable(() => import('./Calculators/ContractorCalculator'));
// const RemortgageCalculator = loadable(() => import('./Calculators/RemortgageCalculator'));

function CalculatorForm(props) {
  let activeFormTemp = {};
  activeFormTemp["monthly_repayments"] = true;
  activeFormTemp["borrow"] = true;
  activeFormTemp["remortgage_calculator"] = true;
  activeFormTemp["buy_to_let_calculator"] = true;
  activeFormTemp["self_employed_calculator"] = true;
  activeFormTemp["contractor_calculator"] = false;
  activeFormTemp["buy_to_let_borrow_calculator"] = true;
  const [activeForm, setActiveForm] = useState(activeFormTemp); 
  useEffect(() => { 
    // let activeFormTemp = activeForm;
    // console.log(props);
    let activeFormTemp = {};
    activeFormTemp["monthly_repayments"] = false;
    activeFormTemp["borrow"] = false;
    activeFormTemp["remortgage_calculator"] = false;
    activeFormTemp["buy_to_let_calculator"] = false;
    activeFormTemp["self_employed_calculator"] = false;
    activeFormTemp["contractor_calculator"] = false;
    activeFormTemp["buy_to_let_borrow_calculator"] = false;
    (props.activeCalculator).map((value,key)=>{
      activeFormTemp[value] = true;
    });
    setActiveForm(activeFormTemp);
  }, []);
  const activeCalculator = props.activeCalculator;
  let containerClass = "col-md-12";
  let disclaimerClass = "d-none";
  if(props.pageId==="calculators"){
    containerClass = "col-md-6";
    disclaimerClass = "";
  }
  if(activeCalculator.length>0){
    return (
      <div className="row justify-content-center">
        {(activeForm["borrow"]) && 
          <div className={`${containerClass} mb-4`}> <Borrow /> </div> }
        {(activeForm["buy_to_let_calculator"]) && 
          <div className={`${containerClass} mb-4`}><BuyToLet /></div>}
        {(activeForm["monthly_repayments"]) && 
          <div className={`${containerClass} mb-4`}><MonthlyRepayments /></div>}
        {(activeForm["buy_to_let_borrow_calculator"]) && 
          <div className={`${containerClass} mb-4`}><BuyToLetBorrow /></div>}
        {(activeForm["contractor_calculator"]) && 
          <div className={`${containerClass} mb-4`}><ContractorCalculator /></div>}
        {(activeForm["self_employed_calculator"]) && 
          <div className="col-md-8 mb-3"><SelfEmployed pageId={props.pageId} /></div>}
        {(activeForm["remortgage_calculator"]) && 
          <div className="col-md-12 mb-3"><RemortgageCalculator /></div>}       
        <div className={`${disclaimerClass} mb-3`}>
          {/* <i>The calculators are indicative and should be used for guidance only and are subject to full financial underwiring, satisfactory property valuation and credit checks.</i> If you need something more accurate, apply for free initial expert mortgage advice, <Link to="/contact" className="" style={{minWidth: 0}}>click here</Link>. */}
          <i>results are indication only and subject to full financial underwriting and satisfactory property valuation.</i>
        </div>
        {/* <div className="container-fluid">
            {/* {(data.acf.content_section_3)? 
              <div className="container mt-5" dangerouslySetInnerHTML={{ __html: data.acf.content_section_3}}></div>
              : ''
            }
            <div className="container py-5 container-section-05 bg-white">
              <h4 className="text-center mb-4 font-bold">Apply for your free initial expert mortgage advice.</h4>
              <div className="text-center">
                <Link to="about-us#contact" className="mfs-btn">Apply now</Link>
              </div>
            </div>
          </div> */}
      </div>
        
    );
  }else{
    return null
  }
}
export default CalculatorForm;
