import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';

function APILoaderView(props) {
    
    // console.log(props);
    return (
        <>
            {[...Array(props.count).keys()].map((item,index)=>{
                return(
                    <div className="rate-group-row p-3 mb-3 c-pointer" key={index}>
                        <div className="row rate-api-row align-items-end">
                            <div className="col-md-2">
                                <Skeleton height="60px"/>
                            </div>
                            <div className="col-md">
                                <div className="rate-value-title">Initial Rate</div>
                                <Skeleton/>
                            </div>
                            <div className="col-md">
                                <div className="rate-value-title">Initial Period</div>
                                <Skeleton/>
                            </div>
                            <div className="col-md">
                                <div className="rate-value-title">Initial Payment</div>
                                <Skeleton/>
                            </div>
                            <div className="col-md-2">
                                <div className="rate-value-title">Lender Fees</div>
                                <Skeleton/>
                            </div>
                            <div className="col-md">
                                <div className="rate-value-title">Total To Pay</div>
                                <Skeleton/>
                            </div>
                            <div className="col-md align-self-center">
                                <div className="btn btn-sm btn-link font-bold text-decoration-none"></div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
}
export default APILoaderView;
