import "babel-polyfill";
import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './index.css';
// import 'font-awesome/css/font-awesome.min.css';
// import "react-tiger-transition/styles/main.min.css";
import './main.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router} from 'react-router-dom';
import {createStore, applyMiddleware} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import allReducers from './reducers';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
window.onload =  window.localStorage.clear();

const middleware = [thunk];
const initialState = {};
const store = createStore(allReducers, initialState, composeWithDevTools(applyMiddleware(...middleware)));
// store.subscribe(() => console.log("current store: ", (store.getState())));
// ReactDOM.render(
//   <Provider store={store}>
//     {/* <Router basename="/test"> */}
//     <Router basename={process.env.REACT_APP_FOLDER}>
//       <App />
//     </Router>
//   </Provider>,
//   document.getElementById('root')
// );
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <Router basename="/test"> */}
    <Router basename={process.env.REACT_APP_FOLDER}>
      <App />
    </Router>
  </Provider>
);
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       {/* <Router basename="/test"> */}
//       <Router basename={process.env.REACT_APP_FOLDER}>
//         <App />
//       </Router>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
