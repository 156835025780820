const initial = '';
const lenderLogos = (state = initial, action)=>{
    switch(action.type){
        case 'LENDER_LOGOS':
                return state = action.data;
        default: 
            return state;
    }
}

export default lenderLogos;