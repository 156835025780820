import loadable from '@loadable/component';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import axios from "axios";
import CookieConsent from "react-cookie-consent";
import {isSafari} from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { mainMenuData, isLoaded, leadsData, trustPilotReviews, servicesMenu, siteMap, footerMenu, footerData, setTrustpilot, faqsData, apiRates, servicesName, pluginApiRates, seoGuidePages, seoLocalPages, seoAdditionalBranchPages} from './actions/actions';
import {getCommonData, getFaqsData, getSavedApiRates, getAllSavedApiRates, getSavedPluginApiRates, getApiRatesDB} from "./lib/api";
import { flatListToHierarchical } from './lib/utils';

// import Home from "./components/Home";
// import PageLoader from "./components/PageLoader";
// import Header from "./components/Header";
// import FixedFooter from "./components/FixedFooter";
// import ErrorPage from "./components/ErrorPage";

// import { CSSTransition } from 'react-transition-group';
import { AnimatePresence } from "framer-motion";
import Faqs from './pages/Faqs';
import SingleDeal from './pages/SingleDeal';
import UpdateLenderLogos from './components/RatesApi/UpdateLenderLogos';
import SingleDealDetails from './pages/SingleDealDetails';
import LenderPage from './pages/LenderPage';
const Home = loadable(() => import('./pages/Home'));
const PageLoader = loadable(() => import ("./components/PageLoader"));
const Header = loadable(() => import ("./components/Header"));
const FixedFooter = loadable(() => import ("./components/FixedFooter"));
const ErrorPage = loadable(() => import ("./pages/ErrorPage"));
const ThankYou = loadable(() => import ("./pages/ThankYou"));
const Test = loadable(() => import ("./pages/Test"));
const Blog = loadable(() => import ("./pages/Blog"));
const LocalMortgage = loadable(() => import ("./pages/LocalMortgage"));

export default function App(){
  // this.setState({ 
  //   data: '',
  //   isLoaded: '', 
  //   error:''
  // })
  // const [data, setData] = useState(null);
  // const [footerMenu, setFooterMenu] = useState(null);
  // const [footerServicesMenu, setFooterServicesMenu] = useState(null);
  // const [logo, setLogo] = useState(null);
  // const [footer, setFooter] = useState(null);
  // const [trustPilot, setTrustPilot] = useState(null);
  // const [siteMap, setSiteMap] = useState(null);
  // const [leadsQuery, setLeadsQuery] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [commonData, setCommonData] = useState('');
  const [faqs, setFaqs] = useState();
  const [ip, setIP] = useState('');
  // const [allowedCountry, setAllowedCountry] = useState(['IND','GB']);
  // const [allowedCountry, setAllowedCountry] = useState(['GB']);

  const dispatch = useDispatch();
  let data = useSelector((state) =>state.mainMenu);
  let siteMapData = useSelector((state) =>state.siteMap);
  let isDataLoaded = useSelector((state) =>state.isLoaded);
  let tempIP;
  let api = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    // getIPDetails(); 
    // console.log(ip);
    let interval;
    // if(allowedCountry.includes(ip)){
      // console.log('welcome');
      interval = setTimeout(()=>{
        loadCommon();
        loadData();
      }, 2000);
    // }   
    return () => {
      source.cancel();
      // if(allowedCountry.includes(ip)){
        clearTimeout(interval);
      // }
    };
  // }, [ip]);
  }, []);
  // const getIPDetails = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/');
  //   tempIP = res.data.country_code; 
  //   // console.log(res.data);
  //   setIP(res.data.country_code);
  // }
  const loadData = async () => {
    try {
      await axios.all([
        axios.get(api+'wp-json/query/v2/get'),
        // axios.get(api+'wp-json/wp-api-menus/v2/menus/2'),
      ]).then(axios.spread((leads, /*obj1, /*obj2, obj3, obj4, obj5,  obj6*/) => {          
        dispatch(leadsData(leads.data));     
        // console.log(leads.data);
        // console.log(obj1.data);
        // dispatch(mainMenuData(obj1.data)); 
        dispatch(isLoaded(true));            
      })).then(()=>{
        // console.log(data);
      })
    } catch (error) {
      console.log(error);
      if (axios.isCancel(error)) {
        console.log("cancelled");
        setError(error);
      } else {
        throw error;
      }
    }
  };
  const loadCommon = async () => {
    const temp = await getCommonData();
    const tempFaqs = await getFaqsData();
    setCommonData(temp);
    setFaqs(tempFaqs);
    let types = ['first-time-buyer', 'remortgaging','buy-to-let-remortgage','buy-to-let-purchase','home-mover'];
    let data = {};
    let dataAPI = {};
    let allSavedData = await getAllSavedApiRates();
    // console.log(allSavedData);
    // data['type'] = types[0];
    // dataAPI[types[0]] = await getSavedApiRates(data);
    // data = {};
    // data['type'] = types[1];
    // dataAPI[types[1]] = await getSavedApiRates(data);
    // data = {};
    // data['type'] = types[2];
    // dataAPI[types[2]] = await getSavedApiRates(data);
    // data = {};
    // data['type'] = types[3];
    // dataAPI[types[3]] = await getSavedApiRates(data);
    // data = {};
    // data['type'] = types[4];
    // dataAPI[types[4]] = await getSavedApiRates(data);
    data = {};
    // let newDBRates = await getApiRatesDB(data);
    // dispatch(pluginApiRates(pluginAPI));
    // console.log(newDBRates);
    // console.log(groupByLTV(newDBRates));
    let pluginAPI = await getSavedPluginApiRates(data);
    dispatch(pluginApiRates(pluginAPI));
    // dispatch(pluginApiRates(groupByLTV(newDBRates)));
    // console.log(pluginAPI);
    // data['service'] = 'buy-to-let';
    // data['ltv'] = '80';
    // Promise.all(
    //   types.map(async(item)=>{
    //     console.log(item);
    //     data = {};
    //     data['type'] = item;
    //   })
    //);
    // console.log(temp.post);
    // console.log(dataAPI);
    // dispatch(apiRates(dataAPI));
    dispatch(apiRates(allSavedData));
    // dispatch(apiRates(newDBRates));
    dispatch(faqsData(tempFaqs[0]['data']));
  }
  const groupByLTV = (data) => {
    const transformed = {};

    Object.keys(data).forEach((category) => {
      transformed[category] = {};

      data[category].forEach((item) => {
        const { LTV } = item;

        if (!transformed[category][LTV]) {
          transformed[category][LTV] = [];
        }

        transformed[category][LTV].push(item);
      });
    });

    return transformed;
  };
  useEffect(() => {
    if(commonData){
      // console.log(commonData);
      let tempFooter = {};
      tempFooter['siteFooter'] = commonData.footers.edges[0].node.siteFooter;
      tempFooter['trustpilotExtras'] = commonData.post.trustpilotExtras;
      // console.log(tempFooter);
      dispatch(footerData(tempFooter));
      dispatch(trustPilotReviews(commonData.trustPilotReviews.nodes));
      // let menus = commonData.menus.nodes;
      let menus = commonData.menus.nodes;
      let result = (menus).reduce(function (r, a) {
        // console.log(r,a);
        r[a.slug] = r[a.slug] || [];
        // r[a.slug].push(a);
        r[a.slug] = a;
        return r;
      }, Object.create(null));
      // console.log(result);
      dispatch(footerMenu(result.links));     
      dispatch(servicesMenu(result.services));  
      let serviceDetails = commonData.services.edges.reduce(function (r, a) {
        r[a.node.slug] = a.node;
        return r;
      }, Object.create(null));   
      dispatch(servicesName(serviceDetails));     
      dispatch(siteMap(result.sitemap)); 
      dispatch(setTrustpilot(commonData.post));
      dispatch(seoGuidePages(commonData.sEOGuidePages));
      dispatch(seoLocalPages(commonData.pages.nodes));
      dispatch(seoAdditionalBranchPages(commonData.additionalBranch.nodes));
      // console.log(commonData);
      // dispatch(mainMenuData(result['main-menu'][0]));  
      // console.log(result.sitemap);
      let mainMenuList = result['main-menu']['menuItems']['nodes']; 
      // console.log( mainMenuList);
      // console.log(flatListToHierarchical( mainMenuList));      
      dispatch(mainMenuData(flatListToHierarchical( mainMenuList))); 
    }
    if(faqs){
      // console.log(faqs[0].data);
      dispatch(faqsData(faqs[0].data));
    }
  }, [commonData,faqs]);
  let locationPage = useLocation();
  // console.log(siteMapData);
  let apiFolder = '';
  if(process.env.REACT_APP_API_FOLDER){
    apiFolder = process.env.REACT_APP_API_FOLDER;
  }
  // window.localStorage.clear();
  // console.log(siteMapData);
  // render() {
    // const { location } = this.props;
    // let { error, isLoaded, data } = this.state;
    
    let isSafariClass = '';
    if(isSafari){
      isSafariClass = 'safari-browser';
    }
    let menu_path= '';
    let url_to= '';
    /*if (!allowedCountry.includes(ip)) {
      return (
        <div className="main-content">
          <div className="container"><div className="text-center">Sorry you are not allowed to view this site.</div></div>
        </div>
      );
    }else*/ if (error) {
      return (
        <div className="main-content">
          <div className="container"><div className="text-center">Error: {error.message}</div></div>
        </div>
      );
    // } else if (!isDataLoaded) {
    //   return (
    //     <div className="main-content">
    //       <div className="loader-container">
    //         <div className="main-content">
    //           <div><img src="logo.png" alt="loader icon"/></div>
    //           <div className="ball-pulse-sync"><div></div><div></div><div></div></div>
    //         </div>
    //       </div>
    //     </div>
    //   );
    } else if (data) {
      return (
        <div className={`App ${isSafariClass}`}>
          {/* <Router basename="/test"> */}
            {/* <Navigation> */}
              {/* {(isDataLoaded)?<Header location={locationPage} ></Header>:''} */}
              <Header location={locationPage} ></Header>
              {/* <Switch> */}
              {/* <TransitionGroup component="div"> */}
                <AnimatePresence in={true} key={locationPage.key}
                  timeout={{ enter: 500, exit: 500 }}
                  classNames='alert' appear={true}
                  // enter={false}
                  unmountOnExit>
                    <div className='new-app-container'>
                      {(!isDataLoaded)?<PageLoader />:
                        <Switch>
                          {
                            siteMapData &&
                            (siteMapData['menuItems']['nodes']).map((menu_item, i) => (
                              menu_path = menu_item['path'].replace(apiFolder, ''),
                              menu_path == '/home/' ? url_to = '/' : url_to = `/${menu_path.replace(/\//g, "")}`,
                              //menu_item['cssClasses']?.length>0? 
                              // url_to = `/${menu_item['cssClasses'][0]}${url_to}` : '', //for url folder services
                              // url_to = url_to.replace(apiFolder, ''),
                              // console.log(url_to.replace(/\//g, "")),
                              <Route path={url_to} key={i} exact
                                render={routeProps => 
                                  <Home pageId={menu_item['connectedNode']['node']['uri'].replace(/\//g, "")} {...routeProps} hash={locationPage.hash} />
                                } 
                              />
                            ))
                          }
                          <Route path="/:type/guides" exact
                            render={routeProps => 
                              <Faqs pageId="faqs" breadcrumbs={true} {...routeProps} />
                            } 
                          />
                          <Route path="/tools/mortgage-guides" exact
                            render={routeProps => 
                              <Blog pageId="faqs" type="mortgage-guide-home" {...routeProps} />
                            } 
                          />
                          <Route path="/mortgage-broker-near-me" exact
                            render={routeProps => 
                              <LocalMortgage pageId="faqs" type="local-mortgage-broker" {...routeProps} />
                            } 
                          />
                          <Route path="/my-local-mortgage-broker" exact
                            render={routeProps => 
                              // <LocalMortgage pageId="faqs" type="local-mortgage-broker" {...routeProps} />
                              <Redirect to="/mortgage-broker-near-me" />
                            } 
                          />
                          <Route path="/tools/my-local-mortgage-broker" exact
                            render={routeProps => 
                              <Redirect to="/mortgage-broker-near-me" />
                            } 
                          />
                          <Route path="/tools/mortgage-broker-near-me" exact
                            render={routeProps => 
                              <Redirect to="/mortgage-broker-near-me" />
                            } 
                          />
                          <Route path="/mortgage-guides/is-it-good-to-remortgage-with-same-lender" exact
                            render={routeProps => 
                              <Redirect to="/mortgage-guides/should-i-remortgage-with-same-lender" />
                            } 
                          />
                          <Route path="/mortgage-guides/should-i-have-35-years-mortgage" exact
                            render={routeProps => 
                              <Redirect to="/mortgage-guides/is-a-35-years-mortgage-normal" />
                            } 
                          />
                          <Route path="/mortgage-guides/mortgage-broker-guide" exact
                            render={routeProps => 
                              <Redirect to="/mortgage-guides/no-fees-mortgage-broker" />
                            } 
                          />
                          {/* <Route path="/tools/mortgage-guides" exact
                            render={routeProps => 
                              <Faqs pageId="faqs" type="home" {...routeProps} />
                            } 
                          /> */}
                          <Route path="/tools/calculators" exact
                            render={routeProps => 
                              <Home pageId="calculators" {...routeProps} hash={locationPage.hash} />
                            } 
                          />
                          <Route path="/tools/rates" exact
                            render={routeProps => 
                              <Home pageId="rates" {...routeProps} hash={locationPage.hash} />
                            } 
                          />
                          <Route path="/tools/mortgage-faqs" exact
                            render={routeProps => 
                              <Home pageId="mortgage-faqs" {...routeProps} hash={locationPage.hash} />
                            } 
                          />
                          <Route path="/:type/rates" exact
                            render={routeProps => 
                              <SingleDeal pageId="rates" breadcrumbs={true} {...routeProps} />
                            } 
                          />                          
                          <Route path="/remortgaging/best-rates" exact
                            render={routeProps => 
                              <Redirect to="/remortgages" />
                            } 
                          />
                          {/* <Route path="/:type/compare-best-rates" exact
                            render={routeProps => 
                              <SingleDealDetails pageId="rates" breadcrumbs={true} {...routeProps} />
                            } 
                          />                        */}
                          <Route path="/remortgaging/compare-best-rates" exact
                            render={routeProps => 
                              <Redirect to="/remortgages" />
                            } 
                          />
                          <Route path="/best-remortgage-deals" exact
                            render={routeProps => 
                              <Redirect to="/remortgages" />
                            } 
                          />
                          <Route path="/first-time-buyer-rates" exact
                            render={routeProps => 
                              <Redirect to="/mortgages-first-time-buyer" />
                            } 
                          />
                          <Route path="/halifax-mortgage-rates" exact
                            render={routeProps => 
                              <Redirect to="/Halifax-2-Years-Fixed-Rate-Mortgage" />
                            } 
                          />
                          <Route path="/mortgage-guides/:slug" exact
                            render={routeProps => 
                              <Blog {...routeProps} />
                            } 
                          />
                          <Route path="/thankyou" exact
                            render={routeProps => 
                              <ThankYou pageId="thankYouPage" {...routeProps} />
                            } 
                          />
                          <Route path="/updateLenders" exact
                            render={routeProps => 
                              <UpdateLenderLogos pageId="updateLenders" {...routeProps} />
                            } 
                          />
                          <Route path="/test" exact
                            render={routeProps => 
                              <Test pageId="test" {...routeProps} />
                            } 
                          />
                          {/* <Route path="/lender_pages/:postpath" exact 
                            render={routeProps =>
                              <div className={`main-content lender-page`}>
                              <LenderPage {...routeProps} />
                              </div>
                            }
                          /> */}
                          <Route 
                            render={routeProps => 
                              <ErrorPage  pageId="errorPage" {...routeProps} />
                            } 
                          />
                        </Switch>
                      }
                    </div>
                </AnimatePresence>
              {/* </TransitionGroup> */}
              {/* </Switch> */}
            {/* </Navigation> */}
            {/* {(isDataLoaded)?<FixedFooter></FixedFooter>:''} */}
            <FixedFooter></FixedFooter>
            <CookieConsent 
              // debug={true} 
              disableStyles buttonClasses="btn btn-primary ml-2 mt-2 mt-md-0" buttonText="Accept &amp; Close" expires={100}>
              Our site uses cookies. By continuing to use our site you are agreeing to our <Link className="link-text" to="/cookie-policy">cookie policy</Link>
            </CookieConsent>
          {/* </Router> */}
        </div>
      );
    }else{
      return null
    }
  // }
}

// export default App;
