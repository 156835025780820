import React from 'react';
import { Link } from 'react-router-dom';

function Breadcrumbs(props){ 
  let pageId = props.pageId;
  let pageUrl = props.pageUrl;
  return (
    <div className="container-fluid container-section breadcrumbs-section">
      <div className="container" >
        <Link to={`/${pageId}`} className="parent"><span>{props.title}</span></Link>
        <Link to={`/mortgage-guides/${pageId}-guide`} className={(props.active==="guides")?"active":""}><span>Guides</span></Link>
        <Link to={`/${pageId}/rates`} className={(props.active==="deals")?"active":""}><span>Rates</span></Link>
        {/* <Link to={`${pageUrl}/${pageId}`} className="parent"><span>{props.title}</span></Link>
        <Link to={`${pageUrl}/${pageId}/guides`} className={(props.active==="guides")?"active":""}><span>Guides</span></Link>
        <Link to={`${pageUrl}/${pageId}/rates`} className={(props.active==="deals")?"active":""}><span>Rates</span></Link> */}
        {/* <Link to={`${pageUrl}/${pageId}/calculators`}><span>Calculators</span></Link> */}
      </div>
    </div> 
  );
}
export default Breadcrumbs;
