import React, { useState, useEffect } from 'react';
// import axios from "axios";
import { Link } from 'react-router-dom';
// import * as qs from 'qs';
// import XMLParser from 'react-xml-parser';
// import { Tabs, Tab, Dropdown, ButtonGroup, Collapse } from 'react-bootstrap';
// import TableView from './TableView';
// import GroupView from './GroupView';
// import { parse } from 'fast-xml-parser';
import { ImSpinner3 } from 'react-icons/im';
// import { GoSettings } from 'react-icons/go';
import { BiSearchAlt } from 'react-icons/bi';
import APIView from './APIView';
// import APITableView from './APITableView';
import { useSelector } from 'react-redux';
// import { apiRates } from '../../actions/actions';
// import { lenderLogos} from '../../actions';
import { getApiRates, removeNegativeRates, sortRatesBy } from "../../lib/api";
// import '../../../src/LenderLogos.css';
import '../../../src/logos.css';
import APILoaderView from './APILoaderView';
import { filterRatesBy } from '../../lib/utils';

function RatesApi(props) {
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeRates, setActiveRates] = useState([]);
    const [currentRates, setCurrentRates] = useState([]);
    // const [savedRates, setSavedRates] = useState({});
    const [newcurrentRates, setNewcurrentRates] = useState([]);
    const [rateCount, setRateCount] = useState(5);
    // const [allLogos, setAllLogos] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [filterMenu, setFilterMenu] = useState(false);
    const [error, setError] = useState('');
    const [invalidInputLoan, setInvalidInputLoan] = useState(false);
    const [invalidInputPropertyPrice, setInvalidInputPropertyPrice] = useState(false);
    const [invalidInputMortgagePrice, setInvalidInputMortgagePrice] = useState(false);
    const [loanTerm, setLoanTerm] = useState(25);
    const [interestOnlyFilter, setInterestOnlyFilter] = useState(true);
    const [purchaseLoanFilter, setPurchaseLoanFilter] = useState(true);
    const [repaymentFilter, setRepaymentFilter] = useState(true);
    const [residentialFilter, setResidentialFilter] = useState(true);
    const [mortgageFixed, setMortgageFixed] = useState(true);
    const [mortgageVariable, setMortgageVariable] = useState(false);
    const [propertyPrice, setPropertyPrice] = useState(200000);
    const [mortgageAmount, setMortgageAmount] = useState(100000);
    const [periodUptoTwo, setPeriodUptoTwo] = useState(true);
    const [periodThree, setPeriodThree] = useState(false);
    const [periodFive, setPeriodFive] = useState(false);
    const [sortBy, setSortBy] = useState("InitialRate");
    const [filtersVisible, setFiltersVisible] = useState({ 'purchase': true, 'remortgage': true, 'residential': true, 'buy-to-let': true });
    const regExNumber = /^[0-9\b]+$/;
    // const [showRates, setShowRates] = useState(props.showRates);
    let showRates = props.showRates;
    // const allApiRates = props.allApiRates;
    // const [allApiRates, setAllApiRates] = useState({ ...useSelector((state) => state.apiRates) });
    // const [allApiRates, setAllApiRates] = useState({ ...useSelector((state) => state.pluginApiRates) });
    let allApiRates = useSelector((state) => state.pluginApiRates);
    // setAllLogos(tempLogos);
    // console.log(allApiRates);
    // console.log(activeRates);
    // console.log(showRates);
    // setAllApiRates({...useSelector((state) =>state.apiRates)});
    // useEffect(() => {
    //     console.log('show rates changes');
    // },[showRates]);
    useEffect(() => {
        // const CancelToken = axios.CancelToken;
        // const source = CancelToken.source();
        // source.cancel();
        // if (showRates) {
        //     // loadLenders();
        //     loadData();
        // }
        // return () => {
        //     source.cancel();
        // };
        setError('');
        setLoanTerm(25);
        setRepaymentFilter(true);
        setPurchaseLoanFilter(true);
        setResidentialFilter(true);
        setMortgageFixed(true);
        setMortgageVariable(false);
        setPropertyPrice(200000);
        setMortgageAmount(100000);
        setPeriodUptoTwo(true);
        setPeriodThree(false);
        setPeriodFive(false);
        // setRateCount(5);
        setSortBy("InitialRate");
        // setShowRates(props.showRates);
        // console.log(allApiRates[props.showRates[0].value]);
        // console.log(allApiRates);
        if (allApiRates[props?.showRates[0]?.value]) {
            let responseData = allApiRates[props.showRates[0].value]['data'];
            if (responseData) {
                responseData = sortRatesBy(responseData, "InitialRate");
                // console.log(responseData);
                setActiveRates([...responseData]);
                setLoading(false);
                // console.log("allApiRates->",allApiRates);
            }
        }
        setFilterVisibility(showRates[0]);
    }, [showRates, allApiRates]);
    // console.log(allApiRates, currentRates,activeRates);
    // console.log(activeRates);
    // console.log(showRates);
    // if(allApiRates[props.showRates[0].value]){
    //     let responseData = allApiRates[props.showRates[0].value]['data'];
    //     responseData = sortRatesBy(responseData,"InitialRate");
    //     setActiveRates([...responseData]);
    // }
    useEffect(() => {
        // console.log("hello");
        // console.log(currentRates);
        // let newcurrentRatesCount = 0
        let currentTemp = filterRatesBy(activeRates, 'InitialRate', 3);
        // if (activeRates.length > 0) {
        //     activeRates.map((item) => {
        //         if (item['InitialRate'] > 3) {
        //             // console.log(" IN IF ")
        //             currentTemp.push(item);
        //             // newcurrentRates[newcurrentRatesCount] = item;
        //             // newcurrentRatesCount ++;
        //         }
        //     })
        //     // setNewcurrentRates([...currentTemp]);
        // }
        let tempArray = currentTemp.slice(0, rateCount);
        setCurrentRates([...tempArray]);
    }, [activeRates, rateCount, allApiRates]);
    useEffect(() => {
        // let responseData = activeRates;
        // responseData = sortRatesBy(responseData, sortBy);
        // setActiveRates([...responseData]);
        loadData();
        // if (!initialLoad) {
        //     loadData();*
        // } else {
        //     setInitialLoad(false);
        // }
    }, [sortBy, showRates]);
    /*useEffect(() => {
        const timer = setTimeout(() => {
            if (allApiRates[props.showRates[0].value]) {
                let responseData = allApiRates[props.showRates[0].value]['data'];
                if (responseData) {
                    responseData = sortRatesBy(responseData, "InitialRate");
                    // console.log(responseData);
                    setActiveRates([...responseData]);
                    // console.log("hello");
                }
            }
        }, 500);
        return () => clearTimeout(timer);
    }, []);*/
    // useEffect(() => {
    //     const CancelToken = axios.CancelToken;
    //     const source = CancelToken.source();
    //     source.cancel();
    //     const loadLenders = async () => {
    //         let api3 = `https://mariannafs.co.uk/promotion/wp-json/get_saved_lenders/v1/get`;
    //         try {
    //             setLoading(true);
    //             await axios.all([
    //                 axios.post(api3)
    //             ]).then(axios.spread((lenders) => {
    //                 // console.log(lenders.data.logos);
    //                 setLoading(false);
    //                 let lenderList = parse(lenders.data.lenders)['s:Envelope']['s:Body']['GetLenderListResponse']['Lenders']['MBPublicSchemeLenders'];
    //                 let lenderLogo = parse(lenders.data.logos)['s:Envelope']['s:Body']['GetLenderLogosResponse']['Logos']['MBPublicImageFile'];
    //                 // console.log(lenderList, lenderLogo);
    //                 const fullLogos = lenderList.map(t1 => ({ ...t1, ...lenderLogo.find(t2 => t2['LenderID'] === t1['ID']) }));
    //                 let results = fullLogos.reduce((hash, obj) => {
    //                     if (obj['LenderID'] === undefined) return hash;
    //                     return Object.assign(hash, { [obj['LenderID']]: (hash[obj['LenderID']] || []).concat(obj) })
    //                 }, []);
    //                 dispatch(lenderLogos(results));
    //                 // setAllLogos([...results]);
    //                 // console.log(results);
    //                 // let jsonDataFromXml = new XMLParser().parseFromString(rates.data);
    //                 // console.log(jsonDataFromXml);
    //             })).then(() => {
    //                 // console.log(data);
    //             });
    //         } catch (error) {
    //             console.log(error);
    //             if (axios.isCancel(error)) {
    //                 console.log("cancelled");
    //                 // setError(error);
    //             } else {
    //                 throw error;
    //             }
    //         }
    //     };
    //     if(!tempLogos){
    //         loadLenders();
    //     }
    //     return () => {
    //         source.cancel();
    //     };
    // }, []);

    function loadMore() {
        setRateCount(prevRate => prevRate + 5);
    }
    const loadData = async () => {
        let data = {};
        data['type'] = props.showRates[0].value;
        data['LoanTerm'] = loanTerm;
        data['mortgageFixed'] = mortgageFixed;
        data['mortgageVariable'] = mortgageVariable;
        data['periodUptoTwo'] = periodUptoTwo;
        data['periodThree'] = periodThree;
        data['periodFive'] = periodFive;
        data['purchaseLoan'] = purchaseLoanFilter;
        data['repayment'] = repaymentFilter;
        data['propertyPrice'] = propertyPrice;
        data['mortgageAmount'] = mortgageAmount;
        data['residential'] = residentialFilter;
        data['sortBy'] = sortBy;
        // let controller = new AbortController();
        if(invalidInputLoan || invalidInputPropertyPrice || invalidInputMortgagePrice){
            return
        }
        try {
            // console.log('payload=>',data);
            // console.log(props.showRates);
            // console.log(props.showRates[0].value);
            setLoading(true);
            const tempApis = await getApiRates(data);
            // controller = null;
            setLoading(false);
            // console.log(tempApis['data']);
            if (tempApis['data']) {
                let responseData = tempApis['data'];
                // responseData = await removeNegativeRates(responseData, 'TotalAddedFees');
                responseData = responseData.filter((res) => {
                    // console.log(parseInt(res['TotalFees'],10)>0);
                    return parseInt(res['TotalFees'],10)>0;
                });
                // console.log(responseData);
                responseData = await sortRatesBy(responseData, sortBy);
                // console.log(responseData);
                setError('');
                setRateCount(5);
                setActiveRates([...responseData]);
            } else if (tempApis['data']==null) {
                let responseData = [];
                setError('');
                setRateCount(5);
                setActiveRates([...responseData]);
            }
            else {
                setError(tempApis['error']);
                setActiveRates([]);
            }

        } catch (error) {
            console.error(error);
            // if (axios.isCancel(error)) {
            //     console.log("cancelled");
            //     // setError(error);
            // } else {
            //     throw error;
            // }
        }
        // return () => controller?.abort();
    };
    function setFilterVisibility(e) {
        let filters = { 'interest_only': true, 'purchase': true, 'remortgage': true, 'residential': true, 'buy_to_let': true };
        // console.log(e);
        switch (e.value) {
            case 'first-time-buyer':
                filters['interest_only'] = false;
                setInterestOnlyFilter(false);
                filters['remortgage'] = false;
                setPurchaseLoanFilter(true);
                filters['buy_to_let'] = false;
                setResidentialFilter(true);
                break;
            case 'remortgaging':
                filters['purchase'] = false;
                setPurchaseLoanFilter(false);
                filters['buy_to_let'] = false;
                setResidentialFilter(true);                
                break;
            case 'buy-to-let-remortgage':
                filters['purchase'] = false;
                setPurchaseLoanFilter(false);
                filters['residential'] = false;
                setResidentialFilter(false);                
                break;
            case 'buy-to-let-purchase':
                filters['remortgage'] = false;
                setPurchaseLoanFilter(true);
                filters['residential'] = false;
                setResidentialFilter(false);                
                break;
            case 'home-mover':
                filters['interest_only'] = false;
                setInterestOnlyFilter(false);
                filters['remortgage'] = false;
                setPurchaseLoanFilter(true);
                filters['buy_to_let'] = false;
                setResidentialFilter(true);                
                break;
            case 'buy-to-let':
                filters['residential'] = false;
                setResidentialFilter(false);                
                break;
            default:
                break;
        }
        setFiltersVisible(filters);
    }
    function handleChange(e) {
        // console.log(e.target.name); 
        switch (e.target.name) {
            case 'sorting':
                setSortBy(e.target.value);
                break;
            case 'loan_type':
                if (e.target.value === "Interest-only") {
                    setRepaymentFilter(false);
                } else {
                    setRepaymentFilter(true);
                }
                break;
            case 'purchase':
                if (e.target.value === "Remortgage") {
                    setPurchaseLoanFilter(false);
                } else {
                    setPurchaseLoanFilter(true);
                }
                break;
            case 'residential':
                if (e.target.value === "Buy to Let") {
                    setResidentialFilter(false);
                } else {
                    setResidentialFilter(true);
                }
                break;
            case 'propertyPrice':
                // if (e.target.value > -1 && e.target.value < 2000001) {
                //     setPropertyPrice(e.target.value);
                // }
                if (e.target.value === '' || regExNumber.test(e.target.value)) {
                    // setPropertyPrice(e.target.value);
                    // if (e.target.value > 0 && e.target.value < 2000001) {
                        setPropertyPrice(e.target.value);
                    // }
                }
                // if(e.target.value === '' || e.target.value > -1 && e.target.value < 2000001){
                if(e.target.value === ''){
                    setInvalidInputPropertyPrice(true);
                }else{
                    setInvalidInputPropertyPrice(false);
                }
                break;
            case 'mortgageAmount':
                // if (e.target.value > 0 && e.target.value < 2000001) {
                if (e.target.value === '' || regExNumber.test(e.target.value)) {
                    setMortgageAmount(e.target.value);
                }
                if(e.target.value === ''){
                    setInvalidInputMortgagePrice(true);
                }else{
                    setInvalidInputMortgagePrice(false);
                }
                break;
            case 'loanTerm':
                // if (e.target.value > 0 && e.target.value < 41) {
                //     setLoanTerm(e.target.value);
                // }
                if (e.target.value === '' || regExNumber.test(e.target.value)) {
                    setLoanTerm(e.target.value);
                }
                if(e.target.value === '' || e.target.value>40){
                    setInvalidInputLoan(true);
                }else{
                    setInvalidInputLoan(false);
                }
                break;
            case 'mortagageFixed':
                if (e.target.value === "fixed") {
                    setMortgageFixed(true);
                    setMortgageVariable(false);
                }
                if (e.target.value === "variable") {
                    setMortgageFixed(false);
                    setMortgageVariable(true);
                }
                break;
            case 'period':
                if (e.target.value === "uptoTwo") {
                    setPeriodUptoTwo(true);
                    setPeriodThree(false);
                    setPeriodFive(false);
                }
                if (e.target.value === "three") {
                    setPeriodUptoTwo(false);
                    setPeriodThree(true);
                    setPeriodFive(false);
                }
                if (e.target.value === "five") {
                    setPeriodUptoTwo(false);
                    setPeriodThree(false);
                    setPeriodFive(true);
                }
                break;
            default:
                break;
        }
    }
    return (
        <div className={`rates-api-container ${(loading) ? 'loading' : ''}`}>
            <ImSpinner3 className="spinner" color="#fff" size={20} />
            <div className="container overflow-visible api-filter-container mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-row align-items-end mb-3">
                            <div className="form-group col-md-3">
                                <label>Mortgage type</label>
                                <div className="d-flex">
                                    <label className={`pr-1 w-100 checkbox-btn c-pointer mb-0`}>
                                        <input type="radio" checked={repaymentFilter} className="btn-checkbox" name="loan_type" value="Repayment" onChange={(e) => handleChange(e)} />
                                        <div className="checkmark">Repayment</div>
                                    </label>
                                    <label className={`pr-1 w-100 checkbox-btn c-pointer mb-0${filtersVisible.interest_only?'':' disabled-option'}`}>
                                        <input type="radio" checked={!repaymentFilter} className="btn-checkbox" name="loan_type" value="Interest-only" onChange={(e) => handleChange(e)} />
                                        <div className="checkmark">Interest-only</div>
                                    </label>
                                </div>
                            </div>
                            <div className="form-group col-md">
                                <label>Property price</label>
                                <input type="number" className={`form-control${invalidInputPropertyPrice?' error':''}`} onChange={(e) => handleChange(e)} name='propertyPrice' value={propertyPrice} />
                            </div>
                            <div className="form-group col-md">
                                <label>Mortgage Term</label>
                                <input type="number" className={`form-control${invalidInputLoan?' error':''}`} onChange={(e) => handleChange(e)} name='loanTerm' value={loanTerm} />
                            </div>
                            <div className="form-group col-md">
                                <label>Mortgage amount</label>
                                <input type="number" className={`form-control${invalidInputMortgagePrice?' error':''}`} onChange={(e) => handleChange(e)} name='mortgageAmount' value={mortgageAmount} />
                            </div>
                            <div className="form-group col-md-2">
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-sm mfs-btn filter-btn ml-1" onClick={() => loadData()}>Search <BiSearchAlt /></button>
                                </div>
                            </div>
                            <div className="logo-stripe-border"></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 pr-md-0">
                        <div id="more-filter-options">
                            <div className="form-row align-items-end">
                                <div className="form-group col-md-12 mt-md-4">
                                    <label className='mb-2'>Filters</label>
                                    <div className="d-flex">
                                        <label className={`w-100 pr-1 checkbox-btn c-pointer mb-0${filtersVisible.purchase?'':' disabled-option'}`}>
                                            <input type="radio" name="purchase" className="btn-checkbox" checked={purchaseLoanFilter} value="Purchase" onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">Purchase</div>
                                        </label>
                                        <label className={`w-100 pl-1 checkbox-btn c-pointer mb-0${filtersVisible.remortgage?'':' disabled-option'}`}>
                                            <input type="radio" name="purchase" className="btn-checkbox" checked={!purchaseLoanFilter} value="Remortgage" onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">Remortgage</div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label></label>
                                    <div className="d-flex">
                                        <label className={`w-100 pr-1 checkbox-btn c-pointer mb-0${filtersVisible.residential?'':' disabled-option'}`}>
                                            <input type="radio" checked={residentialFilter} name="residential" value="Residential" className="btn-checkbox" onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">Residential</div>
                                        </label>
                                        <label className={`w-100 pl-1 checkbox-btn c-pointer mb-0${filtersVisible.buy_to_let?'':' disabled-option'}`}>
                                            <input type="radio" checked={!residentialFilter} value="Buy to Let" name="residential" className="btn-checkbox" onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">Buy to Let</div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label></label>
                                    <div className="d-flex">
                                        <label className="w-100 pr-1 checkbox-btn c-pointer mb-0">
                                            <input type="radio" className="btn-checkbox" name="mortagageFixed" value="fixed" checked={mortgageFixed} onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">Fixed</div>
                                        </label>
                                        <label className="w-100 pl-1 checkbox-btn c-pointer mb-0">
                                            <input type="radio" className="btn-checkbox" name="mortagageFixed" value="variable" checked={!mortgageFixed} onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">Variable</div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Mortgage period</label>
                                    <div className="d-flex">
                                        <label className="w-100 pr-1 checkbox-btn c-pointer mb-0">
                                            <input type="radio" className="btn-checkbox" name="period" value="uptoTwo" checked={periodUptoTwo} onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">2 years</div>
                                        </label>
                                        <label className="w-100 pr-1 checkbox-btn c-pointer mb-0">
                                            <input type="radio" className="btn-checkbox" name="period" value="three" checked={periodThree} onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">3 years</div>
                                        </label>
                                        <label className="w-100 pr-1 checkbox-btn c-pointer mb-0">
                                            <input type="radio" className="btn-checkbox" name="period" value="five" checked={periodFive} onChange={(e) => handleChange(e)} />
                                            <div className="checkmark">5 years</div>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <div className="d-flex justify-content-center">
                                        {/* <div className="btn btn-sm mfs-btn filter-btn mr-1 w-100" onClick={()=>{setFilterMenu(!filterMenu)}} aria-controls="more-filter-options" aria-expanded={filterMenu} >{`${filterMenu? 'Less':'More'}`} filters <GoSettings /></div> */}
                                        <button className="btn btn-sm mfs-btn filter-btn" onClick={() => loadData()}>Update <BiSearchAlt /></button>
                                    </div>
                                </div>
                                <div className="col-md-12 form-group">
                                    <div className="p-2 border mfs-filter-border">
                                        <h5 className="text-center font-bold pb-2 m-0">Next Step</h5>
                                        <div className="text-center pb-3 m-0">If you are ready for a mortgage, get in touch with our advisers, we will find the best mortgage deal suitable for you.</div>
                                        <div className="">
                                            <div className="text-center">
                                                <a href="tel:02080902043" className="mfs-link">Call us on 02080902043</a>
                                            </div>
                                            <div className="text-center">
                                                <Link to="/" className="mfs-link">Submit the form</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="d-flex justify-content-end align-items-center">
                            <label className="pr-2 mb-0">Sort by :</label>
                            <label className=" pl-1 checkbox-btn c-pointer mb-0">
                                <input type="radio" name="sorting" className="btn-checkbox" value="InitialRate" checked={sortBy === "InitialRate" ? true : false} onChange={(e) => handleChange(e)} />
                                <div className="checkmark">Rate</div>
                            </label>
                            {/* <label className=" pl-1 checkbox-btn c-pointer mb-0">
                                <input type="radio" name="sorting" className="btn-checkbox" value="LTV" checked={sortBy === "LTV" ? true : false} onChange={(e) => handleChange(e)} />
                                <div className="checkmark">LTV</div>
                            </label> */}
                            <label className=" pl-1 checkbox-btn c-pointer mb-0">
                                <input type="radio" name="sorting" className="btn-checkbox" value="TotalFees" checked={sortBy === "TotalFees" ? true : false} onChange={(e) => handleChange(e)} />
                                <div className="checkmark">Fees</div>
                            </label>
                        </div>
                        <div className="container1 overflow-visible1">
                            <div className="row1 justify-content-center1">
                                {/* <div className="col-md-12">
                                    <APITableView products={currentRates} />
                                </div> */}
                                {loading &&
                                    <div className="rate-group-view container-fluid1 py-3">
                                        <div className="rate-group-view-container text-center mb-3">
                                            <APILoaderView count={5} />
                                        </div>
                                    </div>
                                }
                                {
                                    // console.log(currentRates),
                                    currentRates.length > 0 && !loading &&
                                    <div className="rate-group-view container-fluid1 py-3">
                                        <div className="rate-group-view-container text-center mb-3">
                                            {
                                                currentRates.map((item, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <APIView value={item} type={props.showRates[0].value} />
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="text-center">
                                            <div className="load-more-btn mfs-btn" onClick={() => loadMore()}>See more</div>
                                        </div>
                                    </div>
                                }
                                {
                                    activeRates.length === 0 && !loading &&
                                    !error &&
                                    <div className="col-md-12 py-3">
                                        <div className="rate-group-view container-fluid1 py-3">
                                            <div className="rate-group-view-container text-center mb-3">
                                                <div className="rate-group-row p-3 mb-3 c-pointer" style={{'pointerEvents': 'none'}}>No products found, please amend the filters!</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    activeRates.length === 0 &&
                                    error &&
                                    <div className="col-md-12 py-3">
                                        <div className="alert alert-danger" role="alert">
                                            <div>{error}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RatesApi;
