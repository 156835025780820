import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

function ModalDetails(props) {    
    const product = props.product;
    
    useEffect(() => {

    }, []);
    // console.log(product);
    return (
            <>
                <Table striped hover size="sm">
                    <tbody>
                        <tr>
                            <td className="font-weight-bold">Lender</td>
                            <td>{product['LenderName']}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Loan Amount</td>
                            <td>£{product['LoanAmount']}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Initial Rate</td>
                            <td>{product['InitialRate']}%</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Repayment Basis</td>
                            <td>{product['RepaymentBasis']}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Initial Monthly Payment</td>
                            <td>£{parseInt(product['InitialMonthlyPayment']).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Initial Term</td>
                            <td>{product['InitialRateControlPeriod']} Month/s</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">APRC</td>
                            <td>{parseInt(product['APRC']).toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Term</td>
                            <td>{product['Term']} Year/s</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Total to pay</td>
                            <td>£{parseInt(product['NYearCostIncFeesExERC']).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Notes</td>
                            <td dangerouslySetInnerHTML={{__html: product['Notes']}}></td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Rate Description</td>
                            <td dangerouslySetInnerHTML={{__html: product['RateDescription']}}></td>
                        </tr>
                    </tbody>
                </Table>
            </>
    );
}
export default ModalDetails;
